import React from "react";
import { Link } from "gatsby";
import styles from "./styles.module.css";
import cat108020 from "../../../../assets/img/cat-108020.jpg";
import cat108030 from "../../../../assets/img/cat-108030.jpg";

export default function PapeterieComponent() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1 className={styles.h1}>Papeterie</h1>
      </div>
      <div className={styles.row}>
        <div class={styles.catHg}>
          <div class={styles.catutl}>
            <div class={styles.catImg}>
              <Link to="/gerez/papeterie/enveloppes/">
                <img src={cat108020} alt="Papeterie Enveloppes" />
              </Link>
            </div>

            <div class="cat-desc-tl">
              <div class={styles.catTitle}>
                <span>Papeterie</span>
                <Link to="/gerez/papeterie/enveloppes/">Enveloppes</Link>
              </div>
            </div>
          </div>

          <div class={styles.catutl}>
            <div class={styles.catImg}>
              <Link to="/gerez/papeterie/papier-lettres/papier-lettres/">
                <img src={cat108030} alt="Papeterie Papier à lettres" />
              </Link>
            </div>

            <div class="cat-desc-tl">
              <div class={styles.catTitle}>
                <span>Papeterie</span>
                <Link to="/gerez/papeterie/papier-lettres/papier-lettres/">
                  Papier à lettres
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
