import React from "react";
import Layout from "../../other/layout";

import SEO from "../../other/seo";
import PapeterieComponent from "../../components/AC-StaticPages/Gerez/Papeterie/Papeterie";

export default function PapeteriaPage() {
  return (
    <Layout>
      <SEO title="Papeterie" />
      <PapeterieComponent />
    </Layout>
  );
}
